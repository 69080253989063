import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import renderBlok from '@renderBlok'
import { H3 } from '@system'
import ContentContainer from '@system/content-container'
import PageContainer from '@system/page-container'
import get from 'lodash/get'
import React from 'react'
import SbEditable from 'storyblok-react'
import { AccordionProvider } from '../../contexts/accordion-context'
import GoogleStructuredData from './google-structured-data'

const useStyles = makeStyles((theme) => ({
  background: ({ backgroundImage }) => ({
    backgroundColor: '#000',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#000',
      backgroundImage:
        !!backgroundImage?.filename && `url(${backgroundImage.filename})`,
      backgroundPosition: 'top right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 800px',
      position: 'relative',
      '&:before': {
        backgroundImage:
          'linear-gradient(0deg, #000 calc(100% - 800px), transparent calc(100% - 700px))',
        content: "''",
        height: '100%',
        position: 'absolute',
        width: '100%',
        zIndex: '0',
      },
    },
  }),
  accordionContentOuter: ({ backgroundImage }) => ({
    padding: '2.5rem 0',
    [theme.breakpoints.up('md')]: {
      position: !!backgroundImage?.filename && 'relative',
      width: !!backgroundImage?.filename && '60%',
      zIndex: !!backgroundImage?.filename && '1',
    },
  }),
  title: ({ backgroundImage }) => ({
    color: backgroundImage?.filename
      ? theme.palette.common.white
      : theme.palette.common.black,
    marginBottom: '1.5rem',
  }),
}))

const AccordionModule = ({ blok }) => {
  const {
    accordionSections,
    backgroundImage,
    isFaqAsStructuredData,
    oneAccordionOpen,
    title,
  } = blok

  const classes = useStyles({ backgroundImage })

  const BackgroundWrapper = ({ children }) =>
    backgroundImage?.filename ? (
      <Box className={classes.background}>{children}</Box>
    ) : (
      children
    )

  return (
    <SbEditable content={blok}>
      {isFaqAsStructuredData && (
        <GoogleStructuredData type="FAQPage" isAccordionFaq={true}>
          {accordionSections.map((section) => ({
            question: section.sectionTitle,
            answer: get(section.expandedContent, '[0].markdown'),
          }))}
        </GoogleStructuredData>
      )}
      <BackgroundWrapper>
        <PageContainer
          backgroundColor={!!backgroundImage?.filename ? 'clear' : 'default'}
        >
          <ContentContainer>
            <Box className={classes.accordionContentOuter}>
              {!!title && (
                <H3 component="h2" className={classes.title}>
                  {title}
                </H3>
              )}
              <AccordionProvider
                hasBackgroundImage={!!backgroundImage?.filename}
                hasOneSectionToggle={
                  oneAccordionOpen || !!backgroundImage?.filename
                }
              >
                <Box>
                  {!!accordionSections &&
                    accordionSections.map((section, sectionIndex) => (
                      <Box key={section._uid}>
                        {renderBlok(section, {
                          sectionIndex,
                        })}
                      </Box>
                    ))}
                </Box>
              </AccordionProvider>
            </Box>
          </ContentContainer>
        </PageContainer>
      </BackgroundWrapper>
    </SbEditable>
  )
}

export default AccordionModule
